<template>
  <div class="test">
    <div class="item">
      <p>来单：(个)</p>
      <div class="val">
        <div>A公司</div>
        <div class="pk">
          <div class="red" style="flex-basis: 40%;"></div>
          <div class="blue"></div>
        </div>
        <div>B公司</div>
      </div>
      <p class="score">落后：2 个</p>
    </div>
    <div class="item">
      <p>协议：(个)</p>
      <div class="val">
        <div>A公司</div>
        <div class="pk">
          <div class="red" style="flex-basis: 50%;"></div>
          <div class="blue"></div>
        </div>
        <div>B公司</div>
      </div>
      <p class="score">势均力敌</p>
    </div>
    <div class="item">
      <p>业绩：(万元)</p>
      <div class="val">
        <div>A公司</div>
        <div class="pk">
          <div class="red" style="flex-basis: 70%;"></div>
          <div class="blue"></div>
        </div>
        <div>B公司</div>
      </div>
      <p class="score">领先：12 万元</p>
    </div>
    <TsxDemo msg="Hi TsxDemo"></TsxDemo>
  </div>
</template>

<script lang="ts">
import { reactive, defineProps, defineComponent } from "vue";
import TsxDemo from "./tsxDemo";
import compareNode from "@/pages/wechatNirvana/components/compareNode.vue";

export default defineComponent({
  props: {

  },
  components: {
    TsxDemo, compareNode
  },
  setup(props,) {

  }
})
</script>

<style lang="less" scoped>
.test {
  padding-top: 50px;
  background: #ccc;
  min-height: 100vh;

  .item {
    padding: 10px 20px;

    p {
      text-align: center;
    }

    .val {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      // padding: 10px 0;
      margin: 10px 0;

      .pk {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 0 10px;
        border-radius: 10px;
        overflow: hidden;
        font-size: 0;

        .red {
          background: red;
          height: 100%;
        }

        .blue {
          flex: 1;
          height: 100%;
          background: blue;
        }
      }


    }

    .score {
      font-size: 10px;
      margin-top: -5px;
      color: red;
    }
  }
}
</style>