
import { defineComponent, PropType } from "vue";

const __default__ = defineComponent({
  props: {
    datas: {
      type: Object as PropType<GetNirvanaDisparityNode2>,
      default: () => { }
    },
    leftColor: {
      type: String,
      default: "#ffc300"
    },
    rightColor: {
      type: String,
      default: "#ec610a"
    }
  },
  setup(props) {

    const getWidth = (a_count: number, b_count: number) => {
      if (a_count == b_count) {
        return "50%"
      } else {
        return parseInt(a_count / (a_count + b_count) * 100 + '') + "%"
      }
    }

    return {
      getWidth
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "a0d50b1c-leftColor": (_ctx.leftColor),
  "a0d50b1c-rightColor": (_ctx.rightColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__