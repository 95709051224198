
import { reactive, defineProps, defineComponent } from "vue";
import TsxDemo from "./tsxDemo";
import compareNode from "@/pages/wechatNirvana/components/compareNode.vue";

export default defineComponent({
  props: {

  },
  components: {
    TsxDemo, compareNode
  },
  setup(props,) {

  }
})
