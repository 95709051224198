<template>
  <div class="compare-node">
    <p v-if="datas.unit">{{ `${datas.title}(${datas.unit})` }}</p>
    <p v-else>{{ `${datas.title}` }}</p>
    <div class="val">
      <div>{{ datas.a_name }}</div>
      <div class="pk">
        <div class="node-left" :style="{ flexBasis: getWidth(datas.a_count, datas.b_count), background: leftColor }">
        </div>
        <div class="node-right" :style="{ background: rightColor }"></div>
        <span class="text-left">{{ datas.a_count }}</span>
        <span class="text-right">{{ datas.b_count }}</span>
      </div>
      <div>{{ datas.b_name }}</div>
    </div>
    <p class="score" v-if="datas.unit">{{ `${datas.a_name} ${datas.diff_title} ${datas.diff_count.toFixed(2)}
          ${datas.unit}`
    }}</p>
    <p class="score" v-else>{{ `${datas.a_name} ${datas.diff_title} ${datas.diff_count.toFixed(2)}` }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    datas: {
      type: Object as PropType<GetNirvanaDisparityNode2>,
      default: () => { }
    },
    leftColor: {
      type: String,
      default: "#ffc300"
    },
    rightColor: {
      type: String,
      default: "#ec610a"
    }
  },
  setup(props) {

    const getWidth = (a_count: number, b_count: number) => {
      if (a_count == b_count) {
        return "50%"
      } else {
        return parseInt(a_count / (a_count + b_count) * 100 + '') + "%"
      }
    }

    return {
      getWidth
    };
  },
});
</script>

<style lang="less" scoped>
.compare-node {

  padding: 10px 20px;

  p {
    text-align: center;
  }

  .val {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin: 10px 0;

    .pk {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0 10px;
      border-radius: 10px;
      overflow: hidden;
      font-size: 0;
      position: relative;

      .node-left {
        // background: v-bind(leftColor);
        height: 100%;
      }

      .node-right {
        flex: 1;
        height: 100%;
        // background: v-bind(rightColor);
      }

      .text-left,
      .text-right {
        position: absolute;
        color: #000;
        font-size: 10px;
        top: 50%;
        transform: translateY(-50%);
        // top: 50% transform: translateX(-50%);
      }

      .text-left {
        left: 10px;
      }

      .text-right {
        right: 10px;
      }
    }
  }

  .score {
    font-size: 10px;
    margin-top: -5px;
  }
}
</style>