import { defineComponent } from "vue";
import style from "./tsxDemo.module.less"

export default defineComponent({
  props: {
    msg: {
      type: String,
      default: "HELLO WORLD!"
    },
  },
  setup(props, context) {
    return () => (
      <div>
        <p class={style.title}>tsxDemo</p>
        <p class={style.content}>{props.msg}</p>
      </div>
    )
  }
})

